const Table = {
	MuiTableCell: {
		styleOverrides: {
			root: {
				fontSize: '12px',
			},
		},
	},
}

export default Table
