import { fonts } from '../fonts'

const Link = {
	MuiLink: {
		styleOverrides: {
			root: {
				color: 'inherit',
				textDecorationColor: 'var(--color-black)',
				'&.linkPrimary': {
					...fonts.helvetica,
					fontSize: '12px',
					fontWeight: 400,
					lineHeight: 1,
					letterSpacing: '1.2px',
					textDecoration: 'none',
					borderBottom: '1px solid',
					borderBottomColor: '#000000',
					paddingBottom: '4px',
					textTransform: 'uppercase',
					color: '#000000',
				},
				'&.linkSecondary': {
					...fonts.helvetica,
					fontSize: '12px',
					fontWeight: 400,
					lineHeight: 1,
					letterSpacing: '1.2px',
					textDecoration: 'none',
					borderBottom: '1px solid',
					borderBottomColor: '#ffffff',
					paddingBottom: '4px',
					textTransform: 'uppercase',
					color: '#ffffff',
				},
			},
			containedPrimary: {
				background: 'unset',
				color: 'var(--color-black)',
			},
			containedSecondary: {
				background: 'unset',
				color: 'var(--color-black)',
			},
			outlinedPrimary: {},
			outlinedSecondary: {},
		},
	},
}

export default Link
