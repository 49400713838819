import { fonts } from '../fonts'

const CssBaseline = {
	MuiCssBaseline: {
		styleOverrides: {
			html: {
				...fonts.helvetica,
				'--layout-max-width': '1440px',
				'--color-white': '#ffffff',
				'--color-black': '#000000',
				'--color-gold': '#B5926D',
				'--color-grey': '#747474',
				'--color-grey-border': '#ECECEC',
				'--color-red': '#920505',
				'--color-grey-light': '#EEEEEE',
			},
			body: {
				...fonts.helvetica,
				color: 'var(--color-black)',
				'& .text-white': {
					color: 'var(--color-white)',
				},
				'& .text-black': {
					color: 'var(--color-black)',
				},
				'& .bg-gray': {
					background: 'var(--color-grey-light)',
				},
				'& .title-alternative': {
					...fonts.times,
				},
			},
		},
	},
}

export default CssBaseline
