import { fonts } from '../fonts'
import { alpha } from '@mui/system'
import defaultTheme from '@bluheadless/ui/src/theme/default'

export const buttonFontSize = 12
export const buttonIconFontSizeMedium = 20

const Button = {
	MuiButton: {
		styleOverrides: {
			root: {
				...fonts.helvetica,
				fontSize: 14,
				fontWeight: 400,
				letterSpacing: '1.4px',
				padding: '12px 32px',
				borderRadius: 0,
				minHeight: 41,
				textTransform: 'uppercase',
				lineHeight: 'normal',
				width: 'max-content',
				[defaultTheme.breakpoints.up('md')]: {
					fontSize: buttonFontSize,
				},
			},
			sizeMedium: {
				'& .MuiButton-iconSizeMedium.MuiButton-startIcon': {
					'& .MuiSvgIcon-fontSizeSmall': {
						fontSize: '1.4rem',
					},
					'& .MuiSvgIcon-fontSizeMedium': {
						fontSize: '1.7rem',
					},
					'& .MuiSvgIcon-fontSizeLarge': {
						fontSize: '1.7rem',
					},
				},
			},
			text: {
				textTransform: 'uppercase',
				minHeight: 'auto',
				padding: '0px 0px 8px',
				letterSpacing: '1.2px',
				fontSize: '12px',
				fontWeight: '700',
				'&:hover': {
					color: 'var(--color-white)',
					borderColor: 'var(--color-white)',
				},
			},
			contained: {
				minHeight: 43,
				minWidth: 'unset',
			},
			containedPrimary: {
				border: '1px solid var(--color-white)',
			},
			containedSecondary: {
				borderWidth: '1px',
				color: 'var(--color-black)',
				borderColor: 'var(--color-black)',
				'&:hover': {
					boxShadow: 'none',
					backgroundColor: 'var(--color-black)',
					color: 'var(--color-white)',
				},
				'&:focus': {
					boxShadow: 'none',
					backgroundColor: alpha('#000000', '0.85'),
					color: 'var(--color-white)',
				},
				'&:active': {
					boxShadow: 'none',
					backgroundColor: 'var(--color-black)',
					color: 'var(--color-white)',
				},
				'&.Mui-disabled': {
					color: 'var(--color-black)',
					backgroundColor: alpha('#000000', '0.2'),
				},
				'& .MuiButton-startIcon': {
					fill: 'var(--color-black)',
				},
				'& >svg': {
					color: 'var(--color-black)',
				},
				'& .MuiButton-endIcon': {
					fill: 'var(--color-black)',
				},
			},
			outlined: {
				borderRadius: '0px',
				borderWidth: '1px',
				padding: '12px 32px',
				minHeight: 41,

				'&:hover': {
					borderWidth: '1px',
				},
				'&:focus': {
					borderWidth: '1px',
				},
				'&:active': {
					borderWidth: '1px',
				},
				'&.Mui-disabled': {
					borderWidth: '1px',
				},
			},
			outlinedPrimary: {
				color: 'var(--color-black)',
				backgroundColor: alpha('#000000', '0.10'),
				backdropFilter: 'blur(15px)',
				borderColor: 'var(--color-black)',
				'&:hover': {
					boxShadow: 'none',
					backgroundColor: 'var(--color-black)',
					color: 'var(--color-white)',
					borderColor: 'var(--color-black)',
				},
				'&:focus': {
					boxShadow: 'none',
					backgroundColor: 'var(--color-black)',
				},
				'&:active': {
					boxShadow: 'none',
					backgroundColor: 'var(--color-black)',
				},
				'&.Mui-disabled': {
					color: 'var(--color-black)',
					backgroundColor: alpha('#ffffff', '0.2'),
				},
				'& .MuiButton-startIcon': {
					fill: 'var(--color-black)',
				},
				'& >svg': {
					color: 'var(--color-black)',
				},
				'& .MuiButton-endIcon': {
					fill: 'var(--color-black)',
				},
			},
			outlinedSecondary: {
				color: 'var(--color-white)',
				backgroundColor: alpha('#ffffff', '0.10'),
				backdropFilter: 'blur(15px)',
				borderColor: 'var(--color-white)',
				'&:hover': {
					boxShadow: 'none',
					backgroundColor: 'var(--color-white)',
					color: 'var(--color-black)',
					borderColor: 'var(--color-white)',
				},
				'&:focus': {
					boxShadow: 'none',
					backgroundColor: 'transparent',
					borderColor: 'var(--color-white)',
				},
				'&:active': {
					boxShadow: 'none',
					backgroundColor: 'var(--color-white)',
					borderColor: 'var(--color-white)',
					color: 'var(--color-black)',
				},
				'&.Mui-disabled': {
					color: 'var(--color-black)',
					backgroundColor: alpha('#ffffff', '0.2'),
				},
				'& .MuiButton-startIcon': {
					fill: 'var(--color-white)',
				},
				'& >svg': {
					color: 'var(--color-white)',
				},
				'& .MuiButton-endIcon': {
					fill: 'var(--color-white)',
				},
			},
			textNoLinkPrimary: {
				...fonts.helvetica,
				color: 'var(--color-black)',
			},
			textPrimary: {
				'&:hover': {
					color: 'var(--color-black)',
					borderColor: 'currentColor',
				},
			},
			textSecondary: {
				'&:hover': {
					color: alpha('#ffffff', '0.8'),
					textDecoration: 'underline',
				},
			},
			textGrey: {
				'&:hover': {
					color: 'var(--color-black)',
					borderColor: 'currentColor',
				},
			},
			textLink: {
				'&:hover': {
					color: 'var(--color-grey)',
					textDecorationColor: 'currentColor',
				},
			},
			startIcon: {
				marginLeft: 0,
			},
		},
	},
	MuiBadge: {
		styleOverrides: {
			colorPrimary: {
				borderWidth: '1px',
			},
			standard: {
				minHeight: 15,
				minWidth: 15,
				transform: 'scale(1) translate(9px, -3px)',
				fontSize: '7px',
			},
		},
	},
}

export default Button
