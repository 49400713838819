import { fonts } from '../fonts'

const Tooltip = {
	MuiTooltip: {
		styleOverrides: {
			tooltip: {
				maxWidth: 'fit-content',
				...fonts.helvetica,
				padding: '0',
				borderRadius: 0,
			},
			tooltipArrow: {
				padding: '0',
			},
			tooltipPlacementTop: {
				padding: '0',
			},
		},
	},
}

export default Tooltip
