const BHSuggestedStoreRow = {
	BHSuggestedStoreRow: {
		defaultProps: {
			labelProps: {
				component: 'span',
				color: 'white.main',
			},
			buttonSelectProps: {
				fullWidth: true,
				align: 'center',
				variant: 'outlined',
				color: 'white',
				size: 'small',
			},
			buttonGoProps: {
				variant: 'outlined',
				color: 'primary',
				size: 'small',
			},
			buttonCloseProps: {
				variant: 'icon',
				iconProps: { fontSize: 'medium', color: 'white' },
			},
		},
	},
}

export default BHSuggestedStoreRow
