const Dropdown = {
	MuiPopover: {
		styleOverrides: {
			paper: {
				'&.no-bg': {
					backgroundColor: 'transparent',
					'.MuiMenu-list': {
						marginTop: 0,
						borderRadius: 0,
						borderTop: 0,
						borderColor: 'rgba(0, 0, 0, 0.20)',
					},
				},
			},
		},
	},
}
export default Dropdown
